/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Ctrl = {
		// All pages
		'common': {
			init: function() {
				//Responsive breakpoints
				$.breakpoint({
					condition: function () {
						return matchMedia('only screen and (min-width:850px)').matches;
					},
					enter: function () {
						$('.layout').flowtype({
							maximum : 1070,
							fontRatio : 58.75
						});
					},
					exit: function () {
						$('.container').removeAttr( "style" );
					}
				});
				$.breakpoint({
					condition: function () {
						return matchMedia('only screen and (min-width:415px) and (max-width:850px)').matches;
					},
					enter: function () {
						$('.layout').flowtype({
							fontRatio : 45.2
						});
					},
					exit: function () {
						$('.layout').removeAttr( "style" );
					}
				});
				$.breakpoint({
					condition: function () {
						return matchMedia('only screen and (max-width:414px)').matches;
					},
					enter: function () {
						
						$('ul#menu-primary-navigation').prependTo('.right-off-page-nav .inner');
						$('.search-form').prependTo('.right-off-page-nav .inner');
						
						var navOpen = false;
						$("#site_header").on("click", ".nav-toggle", function(e) {
							e.preventDefault();
							if(navOpen===false) {
								navOpen = true;
								$('html').addClass('nav-open');
								
								$('.off-page-return').velocity({
									opacity: 1
								},{
									duration: 150
								});
								
								$('div.page-contents').velocity({
									translateX: "-19em"
								},{
									duration: 600,
									easing: [500,20]
								});
								
								$('.nav-toggle').addClass('close');
								
							}
						});
						$('a.off-page-return').click(function(e) {
							e.preventDefault();
							
							navOpen = false;
							
							$('.off-page-return').velocity('reverse');
							
							$('div.page-contents').velocity({
								translateX: "0"
							},{
								duration: 300,
								easing: "easeInSine",
								complete: function() {
									$('html').removeClass('nav-open');
								}
							});
							
							$('.nav-toggle').removeClass('close');
						});
						
					},
					exit: function () {
						$('ul#menu-primary-navigation').prependTo('#site-navigation');
						$('.search-form').appendTo('#search-overlay .center');
					}
				});
				$.breakpoint({
					condition: function () {
						return matchMedia('only screen and (min-width:360px) and (max-width:414px)').matches;
					},
					enter: function () {
						$('.layout').flowtype({
							fontRatio : 24
						});
					},
					exit: function () {
						$('.container').removeAttr( "style" );
					}
				});
				$.breakpoint({
					condition: function () {
						return matchMedia('only screen and (max-width:360px)').matches;
					},
					enter: function () {
						$('.layout').flowtype({
							fontRatio : 21.8
						});
					},
					exit: function () {
						$('.container').removeAttr( "style" );
					}
				});
				
				//Animate Split
				////////////////
				
				//Simple animation
				
				//Animate buttons on hover
				var	scale_links = '.site-header-store-navigation li, #site-navigation li, #search-call, #filters .widget_layered_nav h2, #newsletter .ginput_container input, #newsletter button, .product-remove a, button.button:not(.add_to_cart_button), #review input[type=submit], .widget_layered_nav_filters li, a.button:not(.add_to_cart_button)';
				
				$(".page-contents").on("mouseenter touchstart", scale_links, function(event){
					
					var $target = $( event.target );
					
					$target.addClass('hover');
					
					if($target.hasClass('hover')){
						$target.velocity({
							scale: 1.10
						},{
							duration: 100
						});
					}
				}).on("mouseleave touchend touchcancel touchleave", scale_links, function(event){
					
					var $target = $( event.target );

					if($target.hasClass('hover')){
						$target.velocity("stop");
						$target.velocity('reverse',{complete: function(){
							$target.removeClass('hover');
						}});
					}
				});
				
				$('input[type="checkbox"]').on('click', function(){
					if ( $(this).is(':checked') ) {
						$(this).velocity({rotateZ: 360},{duration:100});
					} 
					else {
						$(this).velocity('reverse');
					}
				});
				
				//Complex animations
				//Brand Button Animation
				$(".page-contents").on("mouseenter touchstart", "a.brand", function(e){
					$(this).addClass('hover');
					
					if(!bowser.msie && !bowser.firefox) {
						$('a.brand.hover #background').velocity({
							rotateZ: 3,
							scale: 1.30
						},{
							duration: 100
						});
						
						//Word chain
						$('a.brand.hover #big').velocity({
							translateX: "-20%",
							rotateZ: -2,
							scale: 1.25
						},{
							delay: 100,
							duration: 100
						});
						
						$('a.brand.hover #bang').velocity({
							translateX: "15%",
							translateY: "-25%",
							rotateZ: -5,
							scale: 1.2
						},{
							delay: 250,
							duration: 100
						});
						
						$('a.brand.hover #music').velocity({
							translateX: "20%",
							translateY: "20%",
							rotateZ: -4,
							scale: 1.3
						},{
							delay: 400,
							duration: 100
						});
					} else {
						$('a.brand').velocity({
							scale: 1.10
						},{
							duration: 100
						});
					}
					
				}).on("mouseleave touchend touchcancel touchleave", "a.brand", function(){
					if(!bowser.msie && !bowser.firefox) {
						$('a.brand.hover #background,a.brand.hover #big,a.brand.hover #bang,a.brand.hover #music').velocity("stop");
						$('a.brand.hover #background,a.brand.hover #big,a.brand.hover #bang,a.brand.hover #music').velocity('reverse');
					} else {
						$('a.brand.hover').velocity("stop");
						$('a.brand.hover').velocity('reverse');
					}
					$(this).removeClass('hover');
				});
				
				
				//Search Animate
				$('#search-call').click(function(e) {
					e.preventDefault();
					
					$('#search-overlay').addClass('active');
					
					$('#search-overlay.active').velocity({
						opacity: 1
					},{
						duration: 150
					});
										
					$('.search-form .input-group').flowtype({
						maximum : 1070,
						fontRatio : 58.75
					});

					$('#search-overlay.active .search-form').velocity({
						translateY: ['0%','200%'],
						opacity: 1
					},{
						duration: 300,
						easing: "easeInSine"
					});
						
					$('#search-form .search-field').focus();
				});
				
				$("body").on('click', '#search-overlay.active, #search-overlay.active .center', function(e) {
					
					if($(e.target).closest('#search-form').length === 0) {
					
						$('#search-overlay.active .search-form').velocity({
							translateY: '-200%',
							opacity: 0
						},{
							duration: 300,
							easing: "easeInSine"
						});
						
						$('#search-overlay.active').velocity({
							opacity: 0
						},{
							delay: 450,
							duration: 150,
							complete: function(){
								$('#search-overlay').removeClass('active');
							}
						});
					
					}
				});
				
				//Match height of articles
				$('.articles article header').matchHeight();
				$('.articles article .entry-summary').matchHeight();
				
				//Update input quantity for ajax cart
				$("form.cart").on("change", "input.qty", function() {
					if (this.value === "0") {
						this.value = "1";
					}
				
					$(this.form).find("button[data-quantity]").data("quantity", this.value);
				});
				
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				
				//Kick off the band!
				the_band();
				
				//Build in the frontpage buttons
				$("#frontpage_buttons li").velocity("transition.slideUpIn", { delay: 50, stagger: 250 });
				
				//Intro Link
				$(".page-contents").on("mouseenter touchstart", ".intro a", function(e){
					$(this).addClass('hover');
					
					$('.intro a.hover').velocity({
						scale: 1.10
					},{
						duration: 100
					});
					
					if(!bowser.msie && !bowser.firefox) {
						$('.intro a.hover #back').velocity({
							rotateZ: -4,
							scale: 1.15,
							translateY: "-5%",
						},{
							duration: 150
						});
						
						$('.intro a.hover #front').velocity({
							rotateZ: 1,
							scale: 1.05
						},{
							delay: 50,
							duration: 150
						});
					} else {
						$('.intro a.hover #back').velocity({
							scale: 1.15,
							translateY: "-5%",
						},{
							duration: 150
						});
						
						$('.intro a.hover #front').velocity({
							scale: 1.05
						},{
							delay: 50,
							duration: 150
						});
					}

				}).on("mouseleave touchend touchcancel touchleave", ".intro a", function(e){
					$('.intro a.hover, .intro a.hover #front, .intro a.hover #back').velocity("stop");
					$('.intro a.hover, .intro a.hover #front, .intro a.hover #back').velocity('reverse'); 
					$(this).removeClass('hover');
				});
				
				//Big buttons links
				var drummin = false;
				$(".page-contents").on("mouseenter touchstart", "#staff_picks", function(e){
					$(this).addClass('hover');
					
					if(!bowser.msie && !bowser.firefox) {
						$('#staff_picks.hover .bottom').velocity({
							rotateZ: 5,
							scale: 1.06
						},{
							duration: 200
						});
						
						$('#staff_picks.hover .top').velocity({
							rotateZ: -3,
							scale: 1.05
						},{
							duration: 150
						});
					} else {
						$('#staff_picks.hover').velocity({
							scale: 1.06
						},{
							duration: 200
						});
					}
					
					
					if(!bowser.msie && !bowser.firefox) {
						if(!drummin) {
							drummin = true;
							$('#staff_picks.hover #drumstic_x5F_top').velocity('callout.jump',{
								complete: function(){
									drummin = false;
								}
							});
							
							$('#staff_picks.hover #drumstic_x5F_top').velocity({
								rotateZ: [-360,0]
							},{
								queue: false,
								duration: 800
							});
						}
					}
				
				}).on("mouseleave touchend touchcancel touchleave", "#staff_picks", function(e) {
					if(!bowser.msie && !bowser.firefox) {
						$('#staff_picks.hover .bottom, #staff_picks.hover .top').velocity("stop");
						$('#staff_picks.hover .bottom, #staff_picks.hover .top').velocity('reverse'); 
					} else {
						$('#staff_picks.hover').velocity("stop");
						$('#staff_picks.hover').velocity('reverse'); 
					}
					$(this).removeClass('hover');
				});
				
				var trumpeting = false;
				$(".page-contents").on("mouseenter touchstart", "#best_sellers", function(e){
					$(this).addClass('hover');
					
					if(!bowser.msie && !bowser.firefox) {
						$('#best_sellers.hover .bottom').velocity({
							scale: 1.06
						},{
							duration: 200
						});
						
						$('#best_sellers.hover .top').velocity({
							rotateZ: -9,
							scale: 1.05
						},{
							duration: 150
						});
					} else {
						$('#best_sellers.hover').velocity({
							scale: 1.06
						},{
							duration: 200
						});
					}
					
					if(!trumpeting) {
						trumpeting = true;
						
						$('#best_sellers.hover .trumpet').velocity({
							rotateZ: -15
						},{
							delay: 100,
							duration: 300
						});
						
						$('#best_sellers.hover .trumpet').velocity('callout.shake',{
							delay: 150
						});
						
						$('#best_sellers.hover .trumpet').velocity({
							rotateZ: 0
						},{
							delay: 80,
							duration: 700,
							complete: function(){
								trumpeting = false;
							}
						});
					}
				
				}).on("mouseleave touchend touchcancel touchleave", "#best_sellers", function(e) {
					if(!bowser.msie && !bowser.firefox) {
						$('#best_sellers.hover .bottom, #best_sellers.hover .top').velocity("stop");
						$('#best_sellers.hover .bottom, #best_sellers.hover .top').velocity('reverse');
					} else {
						$('#best_sellers.hover').velocity("stop");
						$('#best_sellers.hover').velocity('reverse');
					}
					$(this).removeClass('hover');
				});
				
				$('#series').mouseenter(function () {
					$(this).addClass('hover');
					$('#series.hover .bottom').velocity({
						rotateZ: -4,
						scale: 1.06
					},{
						duration: 200
					});
					
					$('#series.hover .top').velocity({
						rotateZ: 4,
						scale: 1.05
					},{
						duration: 150
					});

				}).mouseleave(function () { 
					$('#series.hover .bottom, #series.hover .top').velocity("stop");
					$('#series.hover .bottom, #series.hover .top').velocity('reverse'); 
					$(this).removeClass('hover');
				});
				
				$('#featured').mouseenter(function () {
					$(this).addClass('hover');
					$('#featured.hover .bottom').velocity({
						scale: 1.06
					},{
						duration: 200
					});
					
					$('#featured.hover .top').velocity({
						rotateZ: 5,
						scale: 1.05
					},{
						duration: 150
					});

				}).mouseleave(function () { 
					$('#featured.hover .bottom, #featured.hover .top').velocity("stop");
					$('#featured.hover .bottom, #featured.hover .top').velocity('reverse'); 
					$(this).removeClass('hover');
				});
				
				//Top Seller Links
				$(".page-contents").on("mouseenter touchstart", ".button-wrap", function(e){
					$(this).addClass('hover');
					
					if($(this).hasClass('hover')){
						$(this).velocity({
							scale: 1.10
						},{
							duration: 100
						});
						
						$(this).find('.back').eq(0).velocity({
							rotateZ: -6,
							scale: 1.15,
							translateY: "-5%",
						},{
							duration: 150
						});
						
						$(this).find('.front').eq(0).velocity({
							rotateZ: 3,
							scale: 1.05
						},{
							delay: 50,
							duration: 150
						});
					}

				}).on("mouseleave touchend touchcancel touchleave", ".button-wrap", function(e) {

					if($(this).hasClass('hover')){
						$(this).velocity("stop");
						$(this).find('.back').eq(0).velocity("stop");
						$(this).find('.front').eq(0).velocity("stop");
						
						$(this).velocity('reverse');
						$(this).find('.back').eq(0).velocity('reverse');
						$(this).find('.front').eq(0).velocity('reverse');
					
						$(this).removeClass('hover');
					}
				});
				
				//Add to basket animate =D
				$('body').on('added_to_cart', function(data) {
					stars(data.target,'.actions','.cart');
				});
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
				var	$accordion = $('.profile .content');
				$accordion.find('.draw-outer').css({ display : 'none', overflow : 'hidden'});
				
				$accordion.children('button').click(function() {
					var $this = $(this);
					var $targetContainer	=	$this.next('.draw-outer');
					var $targetDescription	=	$targetContainer.find('.draw').first();
					
					//$('.accordion-element__term').removeClass('active');
					$this.toggleClass('active');
					
					if(!$targetContainer.hasClass('active')) {
						$targetContainer.css('display', 'block');
						$targetDescription.css('margin-top', -$targetDescription.outerHeight());
						$targetDescription.velocity({ marginTop: 0}, { duration: 350 });
						$targetContainer.addClass('active');
					} 
					else {
						$targetDescription.velocity({ marginTop: -$targetDescription.outerHeight()}, { duration: 350 });
						$targetContainer.removeClass('active');
					}
					return false;
				});
			}
		},
		//Woocommerce pages
		'woocommerce': {
			init: function() {
				// Archive page
				if($( "body" ).hasClass( "archive" )) {
					
					$('.products .product').matchHeight();
					
					$.breakpoint({
						condition: function () {
							return matchMedia('only screen and (min-width:850px)').matches;
						},
						enter: function () {
							$('.products li').mouseenter(function () {
								$(this).addClass('hover');
								$('.products li.hover .table').velocity({
									top: 0,
									opacity: 1
								},{
									duration: 150
								});
							}).mouseleave(function () { 
								$('.products li.hover .table').velocity("stop");
								$('.products li.hover .table').velocity('reverse'); 
								$(this).removeClass('hover');
							});
							
							$(".products li ").on("mouseenter touchstart", ".more", function(e){
								$(this).addClass('hover');
								$('.products li .button.hover').velocity({
									scale: 1.1
								},{
									duration: 100
								});
							}).on("mouseleave touchend touchcancel touchleave", ".more", function(e) {
								$('.products li .button.hover').velocity("stop");
								$('.products li .button.hover').velocity('reverse'); 
								$(this).removeClass('hover');
							});
						}
					});
					
					//Filters Accordian Menu
					$( ".widget_layered_nav ul" ).wrap( "<div></div>" );
					
					var	$accordion = $('.widget_layered_nav');
					$accordion.find('div').css({ display : 'none', overflow : 'hidden'});
					
					$accordion.children('h2').click(function() {
						var $this = $(this);
						var $targetContainer =	$this.next('div');
						var $targetDescription =	$targetContainer.find('ul').first();
						
						//$('.accordion-element__term').removeClass('active');
						$this.toggleClass('active');
						
						if(!$targetContainer.hasClass('active')) {
							$targetContainer.css('display', 'block');
							$targetContainer.addClass('active');
							$targetDescription.css('margin-top', -$targetDescription.innerHeight());
							$targetDescription.velocity({ marginTop: 0}, { duration: 350 });
						} 
						else {
							$targetDescription.velocity({ marginTop: -$targetDescription.innerHeight()}, { duration: 350 });
							$targetContainer.removeClass('active');
						}
						return false;
					});
					
					//Pre open grad and series filters
					var $accordionOn = $('.widget_layered_nav.grade, .widget_layered_nav.series');
					$accordionOn.children('h2').toggleClass('active');
					$accordionOn.children('h2').next('div').css('display', 'block');
					$accordionOn.children('h2').next('div').addClass('active');
				
					//Select2 All the dropdowns!
					$('select.orderby').select2({
						minimumResultsForSearch: Infinity
					});
					
					$('body').on("select2:select", function (e) { $('.woocommerce-ordering').submit(); });
					
					//Add to basket animate =D
					$('body').on('added_to_cart', function(data) {
						stars(data.target,'.product','.wrapper',false);
					});
				}
			},
			finalize: function() {
				// Archive page
				//if($( "body" ).hasClass( "archive" )) {
				//	$("ul.products.grid li").not(':first').velocity("transition.slideUpIn", { stagger: 150 });
				//}
			}
		},
		'single_product': {
			init: function() {
				$.breakpoint({
					condition: function () {
						return matchMedia('only screen and (max-width:414px)').matches;
					},
					enter: function () {
						$('div.add-to-cart').insertAfter('.shop_attributes');
					},
					exit: function () {
						$('div.add-to-cart').prependTo('.sidebar');
					}
				});
				
				// Star ratings for comments
				$( '#rating' ).hide().before( '<p class="stars"><span><a class="star-1" href="#"><span>1</span><i class="icon-star"></i></a><a class="star-2" href="#"><span>2</span><i class="icon-star"></i></a><a class="star-3" href="#"><span>3</span><i class="icon-star"></i></a><a class="star-4" href="#"><span>4</span><i class="icon-star"></i></a><a class="star-5" href="#"><span>5</span><i class="icon-star"></i></a></span></p>' );
			
				$( 'body' )
					.on( 'click', '#respond p.stars a', function() {
						var $star   = $( this ),
							$rating = $( this ).closest( '#respond' ).find( '#rating' );
			
						$rating.val( $star.text() );
						$star.siblings( 'a' ).removeClass( 'active' );
						$star.prevAll().addClass( 'active' );
						$star.addClass( 'active' );
			
						return false;
					})
					.on( 'click', '#respond #submit', function() {
						var $rating = $( this ).closest( '#respond' ).find( '#rating' ),
							rating  = $rating.val();
			
						if ( $rating.size() > 0 && ! rating && wc_single_product_params.review_rating_required === 'yes' ) {
							alert( wc_single_product_params.i18n_required_rating_text );
			
							return false;
						}
					});
			
				// prevent double form submission
				$( 'form.cart' ).submit( function() {
					$( this ).find( ':submit' ).attr( 'disabled','disabled' );
				});
				
				//Add to basket animate =D
				$('body').on('added_to_cart', function(data) {
					stars(data.target,'form','.button-wrap');
				});
			}
		},
		'woocommerce_cart': {
			init: function() {
				
				//Select Formating
				$('select').select2({
					minimumResultsForSearch: Infinity
				});	
				
				$( document ).ajaxComplete(function() {
					$('.totals-outer select').select2({
						minimumResultsForSearch: Infinity
					});
				});
				
				//Checkout Button
				$(".page-contents").on("mouseenter touchstart", "a.checkout-button", function(e){
					$(this).addClass('hover');
					
					$('a.checkout-button.hover').velocity({
						scale: 1.10
					},{
						duration: 100
					});
					
					if(!bowser.msie && !bowser.firefox) {
						$('a.checkout-button.hover #back').velocity({
							rotateZ: -2,
							scale: 1.15,
							translateY: "-5%",
						},{
							duration: 150
						});
						
						$('a.checkout-button.hover #front').velocity({
							rotateZ: 1,
							scale: 1.05
						},{
							delay: 50,
							duration: 150
						});
					} else {
						$('a.checkout-button.hover #back').velocity({
							scale: 1.15,
							translateY: "-5%",
						},{
							duration: 150
						});
						
						$('a.checkout-button.hover #front').velocity({
							scale: 1.05
						},{
							delay: 50,
							duration: 150
						});
					}

				}).on("mouseleave touchend touchcancel touchleave", "a.checkout-button", function(e){
					$('a.checkout-button.hover, a.checkout-button.hover #front, a.checkout-button.hover #back').velocity("stop");
					$('a.checkout-button.hover, a.checkout-button.hover #front, a.checkout-button.hover #back').velocity('reverse'); 
					$(this).removeClass('hover');
				});
			}
		},
		'woocommerce_checkout': {
			init: function() {
			},
			finalize: function() {
				
				// Woocommerce Select Formating
				function getEnhancedSelectFormatString() {
					var formatString = {
						formatMatches: function( matches ) {
							if ( 1 === matches ) {
								return wc_country_select_params.i18n_matches_1;
							}
			
							return wc_country_select_params.i18n_matches_n.replace( '%qty%', matches );
						},
						formatNoMatches: function() {
							return wc_country_select_params.i18n_no_matches;
						},
						formatAjaxError: function( jqXHR, textStatus, errorThrown ) {
							return wc_country_select_params.i18n_ajax_error;
						},
						formatInputTooShort: function( input, min ) {
							var number = min - input.length;
			
							if ( 1 === number ) {
								return wc_country_select_params.i18n_input_too_short_1;
							}
			
							return wc_country_select_params.i18n_input_too_short_n.replace( '%qty%', number );
						},
						formatInputTooLong: function( input, max ) {
							var number = input.length - max;
			
							if ( 1 === number ) {
								return wc_country_select_params.i18n_input_too_long_1;
							}
			
							return wc_country_select_params.i18n_input_too_long_n.replace( '%qty%', number );
						},
						formatSelectionTooBig: function( limit ) {
							if ( 1 === limit ) {
								return wc_country_select_params.i18n_selection_too_long_1;
							}
			
							return wc_country_select_params.i18n_selection_too_long_n.replace( '%qty%', limit );
						},
						formatLoadMore: function( pageNumber ) {
							return wc_country_select_params.i18n_load_more;
						},
						formatSearching: function() {
							return wc_country_select_params.i18n_searching;
						}
					};
			
					return formatString;
				}
				
				if ( $().select2 ) {
					var wc_country_select_select2 = function() {
						$( 'select.country_select, select.state_select' ).each( function() {
							var select2_args = $.extend({
								placeholder: $( this ).attr( 'placeholder' ),
								placeholderOption: 'first',
								width: '100%'
							}, getEnhancedSelectFormatString() );
			
							$( this ).select2( select2_args );
						});
					};
			
					wc_country_select_select2();
			
					$( 'body' ).bind( 'country_to_state_changed', function() {
						wc_country_select_select2();
					});
					
				}
				
				$('select.shipping_method').select2({
					minimumResultsForSearch: Infinity
				});

				$( document ).ajaxComplete(function() {
					$('select.shipping_method').select2({
						minimumResultsForSearch: Infinity
					});
				});

				
				//Checkout Button
				$(".page-contents").on("mouseenter touchstart", ".place-order button", function(e){
					$(this).addClass('hover');
					
					$('.place-order button.hover').velocity({
						scale: 1.10
					},{
						duration: 100
					});
					
					if(!bowser.msie && !bowser.firefox) {
						$('.place-order button.hover #back').velocity({
							rotateZ: -2,
							scale: 1.15,
							translateY: "-5%",
						},{
							duration: 150
						});
						
						$('.place-order button.hover #front').velocity({
							rotateZ: 1,
							scale: 1.05
						},{
							delay: 50,
							duration: 150
						});
					} else {
						$('.place-order button.hover #back').velocity({
							scale: 1.15,
							translateY: "-5%",
						},{
							duration: 150
						});
						
						$('.place-order button.hover #front').velocity({
							scale: 1.05
						},{
							delay: 50,
							duration: 150
						});
					}

				}).on("mouseleave touchend touchcancel touchleave", ".place-order button", function(e){
					$('.place-order button.hover, .place-order button.hover #front, .place-order button.hover #back').velocity("stop");
					$('.place-order button.hover, .place-order button.hover #front, .place-order button.hover #back').velocity('reverse'); 
					$(this).removeClass('hover');
				});
			}
		},
		'contact': {
			init: function() {
				if (typeof google !== 'undefined') {
					new Maplace({
						map_div : '.map .map-canvas',
						locations: loc,
						controls_on_map: false,
						map_options: {
							zoom: 12,
							styles: [{"featureType":"all","elementType":"geometry","stylers":[{"hue":"#17A6DA"},{"saturation":38},{"lightness":-17}]}]  
						}
					}).Load();
					
					google.maps.event.addDomListener(window, 'resize', function() {
						map.setCenter(center);
					});
				}
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Ctrl;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
	
	// Elements
	
	// - Header
	// -- Logo
	var logo_bg = $('a.brand #background');
	var logo_big = $('a.brand #big');
	var logo_bang = $('a.brand #bang');
	var logo_music = $('a.brand #music');
	
	// - Homepage
	var double_bass = $('#music_illustration #double_bass');
	var bass_loop = [
		{ elements: double_bass, properties: { rotateZ: 3 }, options: { duration: 600 } },
		{ elements: double_bass, properties: { rotateZ: -3 }, options: { duration: 500, complete: function(){ the_bass(); } } }
	];
	function the_bass(){
		$.Velocity.RunSequence(bass_loop);
	}
	
	var note_1 = $('#music_illustration #note_1');
	var note_1_loop = [
		{ elements: note_1, properties: { translateX: ['30%','-30%'], opacity: [0.6,0] }, options: { duration: 600 } },
		{ elements: note_1, properties: { translateX: '-30%', opacity: 1 }, options: { duration: 600 } },
		{ elements: note_1, properties: { translateX: '30%', opacity: 0.6 }, options: { duration: 600 } },
		{ elements: note_1, properties: { translateX: '-40%', opacity: 0.3 }, options: { duration: 600 } },
		{ elements: note_1, properties: { translateX: '70%', opacity: 0 }, options: { duration: 600, complete: function(){ the_note_1(); } } },
	];
	function the_note_1(){
		$.Velocity.RunSequence(note_1_loop);
		$(note_1).velocity({ translateY: ['-75%','75%'] }, { duration: 3000, queue: false });
	}
	
	var note_2 = $('#music_illustration #note_2');
	var note_2_loop = [
		{ elements: note_2, properties: { translateX: ['-40%','40%'], opacity: [0.6,0] }, options: { delay: 200, duration: 600 } },
		{ elements: note_2, properties: { translateX: '50%', opacity: 1 }, options: { duration: 600 } },
		{ elements: note_2, properties: { translateX: '-50%', opacity: 0.6 }, options: { duration: 600 } },
		{ elements: note_2, properties: { translateX: '60%', opacity: 0.3 }, options: { duration: 600 } },
		{ elements: note_2, properties: { translateX: '-80%', opacity: 0 }, options: { duration: 600, complete: function(){ the_note_2(); } } },
	];
	function the_note_2(){
		$.Velocity.RunSequence(note_2_loop);
		$(note_2).velocity({ translateY: ['-100%','100%'] }, { delay: 200, duration: 3000, queue: false });
	}
	
	var note_3 = $('#music_illustration #note_3');
	var note_3_loop = [
		{ elements: note_3, properties: { translateX: ['35%','-35%'], opacity: [0.6,0] }, options: { delay:200, duration: 700 } },
		{ elements: note_3, properties: { translateX: '-35%', opacity: 1 }, options: { duration: 700 } },
		{ elements: note_3, properties: { translateX: '35%', opacity: 0.6 }, options: { duration: 700 } },
		{ elements: note_3, properties: { translateX: '-45%', opacity: 0.3 }, options: { duration: 700 } },
		{ elements: note_3, properties: { translateX: '75%', opacity: 0 }, options: { duration: 700, complete: function(){ the_note_3(); } } },
	];
	function the_note_3(){
		$.Velocity.RunSequence(note_3_loop);
		$(note_3).velocity({ translateY: ['-75%','75%'] }, { duration: 3700, queue: false });
	}
	
	var piano_keys = $('#music_illustration #piano').children();
	function the_piano(){
		piano_keys = piano_keys.sort(function() {
			return Math.round( Math.random() ) - 0.5;
		});
		var piano_keys_loop = [
			{ elements: piano_keys[0], properties: 'callout.bounce', options: { delay: 50 } },
			{ elements: piano_keys[1], properties: 'callout.bounce', options: { delay: 80 } },
			{ elements: piano_keys[2], properties: 'callout.bounce', options: { delay: 10, sequenceQueue: false } },
			{ elements: piano_keys[3], properties: 'callout.bounce', options: { delay: 90 } },
			{ elements: piano_keys[4], properties: 'callout.bounce', options: { delay: 80, complete: function(){ the_piano(); } } },
		];
		$.Velocity.RunSequence(piano_keys_loop);
	}
	
	var trumpet = $('#music_illustration #trumpet');	
	var trumpet_loop = [
		{ elements: trumpet, properties: {rotateZ: -15}, options: { delay: 4000, duration: 300 } },
		{ elements: trumpet, properties: 'callout.shake', options: { delay: 80 } },
		{ elements: trumpet, properties: {rotateZ: 0}, options: { delay: 80, duration: 700, complete: function(){ the_trumpet(); } } }
	];
	function the_trumpet(){
		$.Velocity.RunSequence(trumpet_loop);
	}
	
	var trombone = $('#music_illustration #trombone');	
	var trombone_loop = [
		{ elements: trombone, properties: {rotateZ: -18}, options: { delay: 3500, duration: 300 } },
		{ elements: trombone, properties: 'callout.shake', options: { delay: 480 } },
		{ elements: trombone, properties: {rotateZ: 0}, options: { delay: 180, duration: 700, complete: function(){ the_trombone(); } } }
	];
	function the_trombone(){
		$.Velocity.RunSequence(trombone_loop);
	}
	
	var sax = $('#music_illustration #sax');
	var sax_loop = [
		{ elements: sax, properties: { rotateZ: 5 }, options: { duration: 1200 } },
		{ elements: sax, properties: { rotateZ: -6 }, options: { duration: 800, complete: function(){ the_sax(); } } }
	];
	function the_sax(){
		$.Velocity.RunSequence(sax_loop);
	}
	
	var drumstick_1 = $('#music_illustration #drumstick_1');
	var drumstick_2 = $('#music_illustration #drumstick_2');
	var drumstick_loop = [
		{ elements: drumstick_1, properties: { translateY: '280%', rotateZ: 5 }, options: { duration: 200, easing: "spring" } },
		{ elements: drumstick_2, properties: { translateY: '350%', rotateZ: 5 }, options: { delay: 500, duration: 210, easing: "spring"} },
		{ elements: drumstick_1, properties: { translateY: '0', rotateZ: 0 }, options: { duration: 200 } },
		{ elements: drumstick_2, properties: { translateY: '0', rotateZ: 0 }, options: { delay: 500, duration: 200, complete: function(){ the_drums(); } } }
	];
	function the_drums(){
		$.Velocity.RunSequence(drumstick_loop);
	}
	
	var note_4 = $('#music_illustration #note_4');
	var note_4_loop = [
		{ elements: note_4, properties: { translateX: ['30%','-30%'], opacity: [0.6,0] }, options: { delay: 400, duration: 900 } },
		{ elements: note_4, properties: { translateX: '-30%', opacity: 1 }, options: { duration: 900 } },
		{ elements: note_4, properties: { translateX: '30%', opacity: 0.6 }, options: { duration: 900 } },
		{ elements: note_4, properties: { translateX: '-40%', opacity: 0.3 }, options: { duration: 900 } },
		{ elements: note_4, properties: { translateX: '70%', opacity: 0 }, options: { duration: 900, complete: function(){ the_note_4(); } } },
	];
	function the_note_4(){
		$.Velocity.RunSequence(note_4_loop);
		$(note_4).velocity({ translateY: ['-120%','20%'] }, { duration: 5000, queue: false });
	}
	
	var flute = $('#music_illustration #flute');
	var flute_loop = [
		{ elements: flute, properties: { rotateZ: -3, translateY: '-4%' }, options: { duration: 1500 } },
		{ elements: flute, properties: { rotateZ: 8, translateY: '5%' }, options: { duration: 1300, complete: function(){ the_flute(); } } }
	];
	function the_flute(){
		$.Velocity.RunSequence(flute_loop);
	}
	
	function the_band(){
		if(!bowser.msie && !bowser.firefox) {
			the_bass();
			the_trumpet();
			the_trombone();
			the_drums();
			the_flute();
		}

		the_note_1();
		the_note_2();
		the_note_3();
		the_note_4();
		the_piano();
		the_sax();
	}
	
	//Starfield add to basket effect 
	//Target is actually the page <.>, parent is the element you wish to target relative to the clicked button, element is the element you wish to place the starfield besides and before is whether that element is before or after.
	function stars(target,parent,element,before) {
		before = typeof before !== 'undefined' ? before : true;
		
		$target = $(target);
		var starfield = '<div class="starfield">';
		var i = 0;
		var x = 0;
		var y = 0;
		var em = 0;
		
		while (i < 2) {
			x = getRandomInt(-5, 20);
			y = getRandomInt(0, 95);
			em = getRandomArbitrary(1, 4);
			starfield += '<i style="position:absolute;top:'+y+'%;left:'+x+'%;font-size:'+em+'em;"class="icon-star"></i>';
			i++;
		}
		
		i = 0;
		while (i < 2) {
			x = getRandomInt(80, 95);
			y = getRandomInt(0, 95);
			em = getRandomArbitrary(1, 4);
			starfield += '<i style="position:absolute;top:'+y+'%;left:'+x+'%;font-size:'+em+'em;"class="icon-star"></i>';
			i++;
		}
		
		starfield += "</div>";
		var $parent = $target.parent().find( '.added_to_cart' ).parents(parent);
		if ($parent.find( '.added_to_cart' ).length > 0) {
			//Remove old starfield
			$parent.find( '.starfield' ).remove();
			
			//Remove stupid added to cart message
			$parent.find( '.added_to_cart' ).remove();
			
			//Insert Starfield before target
			if(before) {
				$parent.find(element).before(starfield);
			} else {
				$parent.find(element).after(starfield);
			}
			
			if ($('.page-contents').find( '.added-message' ).length < 1) {
				$('main.layout').prepend('<div class="added-message" style="opacity:0;display:none;"><p>Basket updated <a href="/cart">View Basket</a></div>');
				$('main.layout .added-message').velocity("fadeIn", { duration: 200 });
			}
			
			$parent.find( '.starfield' ).velocity({color: "#FF8000"},{delay: 600,duration: 700});
			
			$("a.cart-contents").velocity("callout.tada");
			$("a.cart-contents:not(.added)").addClass('added');
			
			$parent.find( '.button-wrap' ).velocity('callout.bounce',200);
			$parent.find( '.icon-star' )
			.velocity("transition.bounceUpInShort", 400)
			.delay(200)
			.velocity("transition.slideUpOutLong", { duration: 600, complete: function(){
				$parent.find( '.starfield' ).remove();
			}});
		}
	}
	
	//Velocity Callouts and Transitions
	
	$.Velocity.RegisterEffect("callout.jump", {
		defaultDuration: 900,
		calls: [ 
			[ { translateY: "-800%" }, 0.50, { easing: [0.25,0.1,0.25,1] } ],
			[ { translateY: 0 }, 0.50, { easing: [0.25,0.1,0.25,1] } ]
		]
	});
	
	$.Velocity.RegisterEffect("transition.bounceUpInShort", {
		defaultDuration: 800,
		calls: [
			[ { opacity: [ 1, 0 ], translateY: [ -30, 50 ] }, 0.60, { easing: "easeOutCirc" } ],
			[ { translateY: 10 }, 0.20 ],
			[ { translateY: 0 }, 0.20 ]
		]
	});
	
	$.Velocity.RegisterEffect("transition.slideUpOutLong", {	
		defaultDuration: 900,
		calls: [
			[ { opacity: [ 0, 1 ], translateY: -200, translateZ: 0 } ]
		],
		reset: { translateY: 0 }
	});
	
	/**
	 * Returns a random number between min (inclusive) and max (exclusive)
	 */
	function getRandomArbitrary(min, max) {
		return Math.random() * (max - min) + min;
	}
	
	/**
	 * Returns a random integer between min (inclusive) and max (inclusive)
	 * Using Math.round() will give you a non-uniform distribution!
	 */
	 
	function getRandomInt(min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}
	

})(jQuery); // Fully reference jQuery after this point.